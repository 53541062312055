import React, { useEffect } from "react";
import * as Icons from "./icons";
import EnergyCard from "./Components/energy-cards";
import PowerList from "./Components/PowerList";
import imageIcon1 from "./icons/co2.png";
import imageIcon2 from "./icons/coal.png";
import imageIcon3 from "./icons/tree.png";
import cardImg1 from "./icons/icon-1.png";
import cardImg2 from "./icons/icon-2.png";
import cardImg3 from "./icons/icon-3.png";
import calendarIcon from "./icons/calendar-icon.png";
import powerIcon from "./icons/power-icon.png";
import Header from "./header";
import List from "./Components/item";
import { Divider, Paper } from "@material-ui/core";
import "./App.css";
import CanvasChart from "./chart";
import useNow from "./hooks/useNow";
import mockData from "./mock";
import formatData from "./utils/formatGraphData";
import Weather from "./Components/weather";
import ListItem from "./Components/List";
import mapImage from "./icons/Kevins Design Header.jpg";
import axios from "axios";

//const mock = formatData({ ...mockData })

const cards = [
	{
		icon: imageIcon1,
		multiply: 0.4,
		unit: "Ton",
	},
	{
		icon: imageIcon2,
		multiply: 0.074,
	},
	{
		icon: imageIcon3,
		multiply: 20,
	},
];

let powersDetails = [
	{
		title: "Power",
		value: "428",
		icon: cardImg1,
		unit: "KWp",
		titleIcon: powerIcon,
		bottomText: "PV Installed Capacity",
		bottomValue: "3.34",
		bottomUnit: "MWp",
		id: "#1",
	},
	{
		title: "Today's Energy",
		value: "298",
		icon: cardImg2,
		unit: "KWh",
		titleIcon: calendarIcon,
		id: "#2",
	},
	{
		title: "Total Energy",
		value: "8218",
		icon: cardImg3,
		unit: "MWh",
		titleIcon: calendarIcon,
		id: "#3",
	},
];

const solar_data = [
	{
		id: "Academy",
		title: "Academy Solar Profile",
		url:
			"https://lnwisd4ve6.execute-api.ap-southeast-1.amazonaws.com/academy",
	},
	{
		id: "Asia",
		title: "Asia Hub Solar Profile",
		url:
			"https://vsbkp56gsk.execute-api.ap-southeast-1.amazonaws.com/asiahub",
	},
	{
		id: "Siglap",
		title: "Siglap Solar Profile",
		url:
			"https://77px7fvh9k.execute-api.ap-southeast-1.amazonaws.com/siglap",
	},
];

class Map extends React.Component {
	componentDidMount() {
		window.addEventListener("resize", () => {
			this.setState({
				height: this.getHeight(),
			});
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", () => {});
	}

	getHeight = () => {
		return window.innerWidth > window.innerHeight ? "55vw" : "40vh";
	};

	state = {
		height: this.getHeight(),
	};

	render() {
		const { height } = this.state;
		return (
			<div className="map-view">
				<img src={mapImage} alt="map" />
				{this.props.children}
			</div>
		);
	}
}

function App() {
	React.useEffect(() => {
		if (window.location.pathname === "/") {
			window.location.pathname = "/dbs";
		}
	});
	React.useEffect(() => {}, []);
	const [power, setPower] = React.useState(0);
	// let power = 0;

	const powerCallback = (_power) => {
		setPower(power + _power);
	};

	const renderCard = (card, i) => {
		return <ListItem key={i} card={card} power={power} />;
	};

	const renderData = (d, i) => (
		<div key={i} className="solar-chart-card-container">
			<div className="title-chart">{d.title}</div>
			<div className="solar-chart-card">
				<CanvasChart key={i} {...d} powerCallback={powerCallback} />
			</div>
		</div>
	);

	const now = useNow();
	return (
		<div style={{ background: "#4f4f4f" }}>
			<div style={{ height: "95px" }}>
				<Header />
			</div>
			<Map />
			<div className="chart-area">
				<EnergyCard url="https://7trhxoztmd.execute-api.ap-southeast-1.amazonaws.com/allStationData">
					{(data) => (
						<div style={{ display: "flex" }}>
							<div style={{ width: "65%" }}>
								<div className="flex-box">
									{cards.map(renderCard)}
								</div>
								{solar_data.map(renderData)}
							</div>
							<div style={{ width: "35%", paddingLeft: 30 }}>
								<Weather now={now} />
								<PowerList
									powersDetails={powersDetails}
									power={power}
									data={data}
								/>
							</div>
						</div>
					)}
				</EnergyCard>
			</div>
		</div>
	);
}

export default App;
