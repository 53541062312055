import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import formatData from "./utils/formatGraphData";
import { demoData } from "./mock";

const getChartHeight = () => {
	let r;
	const height =
		window.innerHeight > window.innerWidth
			? window.innerHeight
			: window.innerWidth;
	const area = (height / 100) * 70;
	const chartArea = area - 400;
	r = chartArea / 3;
	if (r < 200) r = 200;
	return r + "px";
};

let date = new Date();
date.setHours(7);
date.setMinutes(0);
date.setSeconds(0);

let maxDate = new Date();
maxDate.setHours(19);
maxDate.setMinutes(0);
maxDate.setSeconds(0);

const getDateRange = () => {};

export default class Chart extends React.Component {
	state = {
		dateRange: getDateRange(),
		options: {
			exporting: {
				enabled: false,
			},
			chart: {
				type: "spline",
				scrollablePlotArea: {
					minWidth: 600,
					scrollPositionX: 1,
				},
				height: getChartHeight(),
			},
			title: {
				text: this.props.title,
				align: "left",
			},
			xAxis: {
				type: "datetime",
				labels: {
					overflow: "justify",
				},

				plotBands: [
					{
						// Light air
						width: 5,
						color: "rgba(68, 170, 213, 0.1)",
						label: {
							style: {
								color: "red",
							},
						},
					},
				],
			},
			yAxis: {
				title: {
					text: "Power",
				},
				minorGridLineWidth: 0,
				gridLineWidth: 0,
				alternateGridColor: null,
				plotBands: [
					{
						// Light air
						width: 5,
						color: "rgba(68, 170, 213, 0.1)",
					},
				],
			},
			tooltip: {
				valueSuffix: " KWp",
			},
			plotOptions: {
				spline: {
					lineWidth: 4,
					states: {
						hover: {
							lineWidth: 5,
						},
					},
					marker: {
						enabled: false,
					},
					pointInterval: 3600000, // one hour
					pointStart: Date.UTC(2018, 1, 13, 7, 0, 0),
				},
			},
			series: [
				{
					color: "#FF708B",
					name: "",
					data: [],
				},
			],
			navigation: {
				menuItemStyle: {
					fontSize: "10px",
				},
			},
			global: {
				timezoneOffset: 10 * 60,
			},
			time: {
				useUTC: false,
			},
		},
	};

	componentDidMount() {
		if (this.props.mock) {
			// console.log("chklajfk",this.props.mock)
			this.setState({
				options: {
					...this.state.options,
					series: [
						{
							color: "#2338edfc",
							name: "",
							data: this.props.mock,
						},
					],
				},
			});
		} else {
			this.refresh();
			//this.interval = setInterval(this.refresh,900000);
		}

		window.addEventListener("resize", () => {
			this.setState({
				options: {
					...this.state.options,
					chart: {
						type: "spline",
						scrollablePlotArea: {
							minWidth: 600,
							scrollPositionX: 1,
						},
						height: getChartHeight(),
					},
				},
			});
		});
	}

	refresh = () => {
		const { url } = this.props;
		//const value= formatData(demoData);
		axios
			.get(url)
			.then((res) => {
				if (
					res.data &&
					res.data.length > 0 &&
					typeof this.props.powerCallback === "function"
				) {
					this.props.powerCallback(
						res.data[res.data.length - 1].value
					);
				}

				const data = formatData(res.data);
				console.log("id=", this.props.id, data);
				this.setState({
					options: {
						...this.state.options,
						series: [
							{
								color: "#FF708B",
								name: "",
								data,
							},
						],
					},
				});
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	componentWillUnmount() {
		if (this.interval) clearInterval(this.interval);
		window.removeEventListener("resize", () => {});
	}

	render() {
		//console.log("...dsfsfgfg", this.state.options);
		const { options, show } = this.state;
		return (
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
				oneToOne={true}
			/>
		);
	}
}
