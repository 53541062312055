const toNum = (n) => {
	if (n < 9) return `0${n}`;
	return n;
};

const toTime = (d = new Date()) => {
	//console.log("ddddd", d)
	let h = d.getHours();
	if (h > 12) h = 12 - h;
	return `${toNum(h)}:${toNum(d.getMinutes())}`;
};

export default (d) => {
	//console.log("+++", d.length);
	return d.map((i) => ({
		y: i.value,
		x: i.timeStamp,
		name: toTime(new Date(i.timeStamp * 1000)),
	}));
	//toTime(new Date(i.timeStamp * 1000))])
};

// const powerList = [];
// export const savePowerValue = (id, data) => {
// 	const length = data.length;
// 	let selected = -1;	
//     const powerObject = {};   
// 	Object.entries(powerList).map((list, index) => {
        
// 		if (list[id] === id) {
//             selected = index;
//             console.log("insert")
// 		}
// 	});
// 	if (selected === -1) {
// 		powerObject["id"] = id;
// 		powerObject["value"] = data[length - 1].value;
// 		powerList.push(powerObject);
// 	} else {
// 		powerList[selected] = data[length - 1].value;
// 	}
// 	console.log("powerList", powerList);
// };
// export const getTotalPower = () => {
// 	let total = 0;
// 	for (let item of powerList) {
// 		total += item.value;
// 	}
// 	total = total * 0.001;
// 	console.log("checkPOWER",total);
// 	return total;
// };
