import React from "react";
import "./style.scss";

const ListCard = ({ card, power }) => {
	let value = power * 0.001 * card.multiply;
	//console.log("poer", value);
	return (
		<div id="list-Card">
			<img className="image-list" src={card.icon} />
			<div className="number-list">{value.toFixed(2)}</div>
			<div className="ton-list">{card.unit}</div>
		</div>
	);
};

export default ListCard;
