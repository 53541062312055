import React from 'react'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const amPm = (h) => h > 12 ? 'PM' : 'AM'
const toNum = (n) => {
  if (n < 9) return `0${n}`
  return n
}

export default () => {
  const [today, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  }, []);
  let h = today.getHours()
  if (h > 12) h = h - 12;
  return `${months[today.getMonth()]} ${today.getDate()},${today.getFullYear()}#${toNum(h)}:${toNum(today.getMinutes())}:${toNum(today.getSeconds())} ${amPm(today.getHours())} `
};