import React from "react";
import {
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
	CardSubtitle,
	Button,
} from "reactstrap";
import Grid from "@material-ui/core/Grid";
import "./style.scss";
import cardImg1 from "../../icons/Group 99.png";
import cardImg2 from "../../icons/Group 100.png";
import cardImg3 from "../../icons/Group 101.png";

import powerIcon from "../../assets/power.png";
import axios from "axios";

const cards = [
	{ title: "Power", value: "428", icon: cardImg1 },
	{ title: "Total Power", value: "8218", icon: cardImg3 },
	{ title: "Today's Energy", value: "298", icon: cardImg2 },
];
class Wrapper extends React.Component {
	state = {
		data: null,
	};

	componentDidMount() {
		this.refresh();
		this.interval = setInterval(this.refresh, 300000);
	}

	componentWillUnmount() {
		if (this.interval) clearInterval(this.interval);
	}

	refresh = () => {
		axios
			.get(this.props.url)
			.then((res) => {
                const data = res.data;
				this.setState({
					data,
				});
			})
			.catch((err) => {});
	};

	renderCard = (card, i) => {
		return <EnergyCard key={i} card={card} />;
	};

	render() {
		const { data } = this.state;
		return (
			<div className="cards-container">
				{/* { cards.map(this.renderCard) } */}
				<div className="children-wrapper">
					{this.props.children(data)}
				</div>
			</div>
		);
	}
}

export default Wrapper;

export const EnergyCard = ({ card }) => (
	<div className="energy-card one-third-width">
		<div>
			<img className="energy-card-icon" src={powerIcon} />
		</div>
		<div className="card-text">
			<div className="title">{card.title}</div>
			<h1>{card.value}</h1>
		</div>
	</div>
);
