import React, { useEffect } from "react";
import "./style.scss";

const Card = ({ card, power, data }) => {	
	let _power =power!=undefined? power * 0.000001:0;
	//console.log("power",power,_power,data);
	return (
		<div className="power-card">
			<div className="top-flex-box">
				<div>
					<img className="title-Icon" src={card.titleIcon} />
				</div>

				<div className="left-container">
					<div className="title">{card.title}</div>
					<div className="card-flex-box">
						<div className="label">
							{card.id === "#1"
								? _power.toFixed(4)
								: card.id === "#2"
								? (data.day_power/1000).toFixed(4)
								: (data.total_power/1000).toFixed(4)}
						</div>
						<div>{card.unit}</div>
					</div>
					<img className="img-style" src={card.icon} />
					{card.bottomText ? (
						<div>
							<div className="line-divider"></div>
							<div className="bottom-flex-box">
								<div className="bottom-text">
									{card.bottomText}
								</div>
								<div className="bottom-value">
									{card.bottomValue}
									<span className="bottom-unit">
										{card.bottomUnit}
									</span>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

const PowerList = ({ powersDetails, power, data }) => {
	//console.log("data____", data);
	const renderPowerCards = (card, index) => {
		return (
			<Card
				card={card}
				key={index}
				data={
					data
						? data.status === 429
							? { day_power: 0, total_power: 0 }
							: data.data
						: { day_power: 0, total_power: 0 }
				}
				power={power}
			/>
		);
	};
	return <div id="power-list">{powersDetails.map(renderPowerCards)}</div>;
};

export default PowerList;
