import React, { useState } from "react";
import logo from "./dbsHeader.png";

export default () => (
  <div style={{ height: "60px", background: "#4f4f4f", display: "flex", alignItems: "center", color: "white", padding: '20px' }}>
    <img
      src={logo}
      alt="..."
      style={{
        width: 132
      }}
    />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <h2>Renewable Energy Dashboard</h2>
  </div>
)