import React from 'react'
import axios from 'axios'
import "./style.scss"
import moment from "moment"

export default class extends React.PureComponent {

    state = {
        temp: 0,
        icon: null,
        status: '',
        time: ""
    }

    componentDidMount() {
        this.refresh()
        this.interval = setInterval(this.refresh, 900000)
    }

    componentWillUnmount() {
        if (this.interval)
            clearInterval(this.interval)
    }

    refresh = () => {
        axios.get('https://k11ofs2jwl.execute-api.ap-southeast-1.amazonaws.com/getWeather')
            .then(res => {
                const data = res.data.status
                this.setState({
                    temp: data.current.temp_c,
                    icon: data.current.condition.icon,
                    status: data.current.condition.text,
                    time: data.location.localtime
                })
            })
            .catch(err => {

            })
    }

    getDateTime = (now) => {
        let dateTime = now.split("#")
        const time = dateTime[1].split(" ");
        const date = dateTime[0].split(",");

        return {
            time: time[0],
            amPm: time[1],
            date: date[0],
            year: date[1]
        }
    }

    render() {

        const { temp, status, icon, time, loading } = this.state;
        const { now } = this.props;
        if (loading) return 'Loading...'
        let dateTime = this.getDateTime(now);
        return (
            <div id="weather-card">
                <div className="time-container">
                    <span className="weather-card-time">
                        {dateTime.time}
                    </span>
                    <span className="weather-card-ampm">
                        {dateTime.amPm}
                    </span>
                </div>
                <div className="time-container">
                    <span className="weather-card-date">
                        {dateTime.date}
                    </span>
                    <sup className="superScript">TH</sup>
                    <span className="weather-card-year">
                        ,{dateTime.year}
                    </span>
                </div>
                <div className="divider"></div>
                <div className="weather-flex-box">
                    <div>
                        <span className="temp">{temp}
                            <span className="degree-temp">℃</span>
                        </span>
                        <div className="status">{status}</div>
                    </div>
                    <img src={icon} alt='symbol' />
                </div>
            </div>
        )
    }
}